<!--
* @Descripttion: 成交的项目
* @version: 1.0
* @Author: Niklaus
* @Date: 2021-08-26 20:49:58
-->
<template>
	<el-main>
		<h1 class="base-title">成交的项目</h1>
		<div class="search mt">
			<el-input  placeholder="请输入内容"  v-model="searchVal"  clearable :input-style="inputStyle" @clear="onProjectList"></el-input>
			<el-button type="primary" @click="onProjectList">搜索</el-button>
		</div>

		<el-table :data="tableData" style="width: 100%">
			<el-table-column label="#" type="index" min-width="5%">
			</el-table-column>

			<el-table-column label="项目编号" min-width="10%">
				<template #default="scope">
					<span >{{ scope.row.projectNo }}</span>
				</template>
			</el-table-column>
			<el-table-column label="项目名称" min-width="20%">
				<template #default="scope">
					<span >{{ scope.row.projectName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="项目类型" min-width="10%">
				<template #default="scope">
					<span >{{ scope.row.typeName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="报价方式" min-width="10%">
				<template #default="scope">
					<span class="el-link el-link--primary">{{ scope.row.quotationMethod | purchaseType }}</span>
				</template>
			</el-table-column>
			<el-table-column label="竞价时间" min-width="10%">
				<template #default="scope">
					<span>{{ scope.row.biddBeginDatetime }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="是否收取服务费" min-width="10%">
				<template #default="scope">
					<span v-if="scope.row.chargeFlag == '1'" class="el-link el-link--primary">是</span>
					<span v-else-if="scope.row.chargeFlag == '2'" class="el-link el-link--primary">否</span>
					<span v-else class="el-link el-link--primary">是</span>
				</template>
			</el-table-column>			 -->
			<el-table-column label="服务费" min-width="10%">
				<template #default="scope">
					<span v-if="scope.row.resultNoticeUrl">
						<template v-if="scope.row.chargeFlag=='1'">
							<el-button size="mini" type="primary" @click="onShowChargeDetail(scope.row)">查看</el-button>
						</template>
					</span>
				</template>
			</el-table-column>
      <el-table-column label="开票信息" min-width="10%">
				<template #default="scope">
					<span v-if="scope.row.resultNoticeUrl">
						<template v-if="scope.row.chargeFlag=='1'">
              <template v-if="scope.row.invoiceTitle">
                <el-button size="mini" type="success" @click="showInvoice(scope.row)">查看开票信息</el-button>
              </template>
              <template v-else>
                <el-button size="mini" type="info" @click="openInvoice(scope.row)">填写开票信息</el-button>
              </template>
						</template>
					</span>
				</template>
			</el-table-column>
			<el-table-column label="成交通知书" min-width="10%">
				<template #default="scope">
					<span v-if="scope.row.resultNoticeUrl">
						<template v-if="scope.row.chargeFlag=='2'||(scope.row.chargeFlag=='1'&&scope.row.payFlag=='1')">
							<el-button size="mini" type="primary" @click="handleView(scope.row)">查看成交通知书</el-button>
						</template>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<div class="block page-pagination">
			<el-pagination
			prev-text="上一页"
			next-text="下一页"
			layout="prev, pager, next"
			@current-change="goPage"
			:total="total">
			</el-pagination>
		</div>

		<!-- Form -->
		<el-dialog title="收取服务费详情" :visible.sync="dialogFormVisible" width="600px" center>
			<el-form :model="form" label-width="100px">
				<el-form-item label="是否收取服务费">
					<el-input v-if="form.chargeFlag=='1'" value="是" placeholder="请输入内容" readonly="true"></el-input>
					<el-input v-else-if="form.chargeFlag=='2'" value="否" placeholder="请输入内容" readonly="true"></el-input>
					<el-input v-else value="否" placeholder="请输入内容" readonly="true"></el-input>
				</el-form-item>

				<el-form-item label="服务费描述" v-if="form.chargeFlag=='1'">
					<el-input type="textarea" :rows="10" v-model="form.serviceChargeDesc" autocomplete="off" readonly="true" ></el-input>
				</el-form-item>

				<el-form-item label="图片" v-if="form.chargeFlag=='1'&&form.chargePic">
					<el-image
						style="width: 100px; height: 100px"
						:src="form.chargePic"
						:preview-src-list="srcList">
					</el-image>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onCloseChargeDetail">关闭</el-button>
				<!-- <el-button type="primary">确 定</el-button> -->
			</div>
		</el-dialog>


    <!--
     * @Description:  填写开票信息
     * @Author: Niklaus
     * @Date: 2022-07-28 14:03:29
     -->
    <el-dialog
      title="开票信息"
      :visible.sync="showInvoiceDialog"
      width="30%"
      center
      @closed="closeInvoiceDialog"
    >
      <el-form :model="invoiceInfo" ref="invoiceInfoform" label-width="80px" :inline="false" size="normal">
        <el-form-item label="抬头名称" prop="invoiceTitle" :rules="[{ required: true, message: '请输入抬头名称', trigger: 'blur' }]">
          <el-input v-model="invoiceInfo.invoiceTitle" :readonly="onlyRead"></el-input>
        </el-form-item>
        <el-form-item label="税号" prop="invoiceTaxNumber" :rules="[{ required: true, message: '请输入税号', trigger: 'blur' }]">
          <el-input v-model="invoiceInfo.invoiceTaxNumber" :readonly="onlyRead"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" :rules="[{ required: true, message: '请输入邮箱', trigger: 'blur' }]">
          <el-input v-model="invoiceInfo.email" :readonly="onlyRead"></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 40px; text-align: center" label-width="0px" >
          <el-button type="primary" @click="handleSubmit" v-if="!onlyRead">提交</el-button>
          <el-button @click="showInvoiceDialog =  false">{{ onlyRead ? '关闭' : '取消' }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


	</el-main>
</template>

<script>
import {httpPost,httpGet,httpPut} from '@/utils/http'
import global from '@/common/global';
export default {
	name: 'OnGoing',
	data(){
		return {
			searchVal: '',
			inputStyle:{width:'300px'},
			tableData: [],
			total:0,
			queryParam: {
				supplierStoreId: "",
				partakeStatus:"",
				projectName: "",
				projectNo: "",
				processStatus: "",
				processDetailStatus: ""
			},
			pageParams:{
				pageNo:1,
				pageSize:10
			},
			urls:{
				projectList: "/ewbid/bidd/supplierProjectController/dealList",
        addInvoice: '/ewbid/biddeal/tbProjectInvoice/add'
			},
			dialogFormVisible: false,
      form: {
        chargeFlag: '1',
        serviceChargeDesc: '',
				chargePic: ''
      },
			srcList:[],
      showInvoiceDialog: false,
      onlyRead: false,
      /** 开票信息 */
      invoiceInfo: {
        projectId:'',
        projectNo: '',
        quotationMethod: '',
        invoiceTitle: '',
        invoiceTaxNumber: '',
        email:''
      }
		}
	},
	beforeMount(){
    this.queryParam.supplierStoreId = this.$ls.get("storeInfo").id
		this.onProjectList();
	},
	methods: {
		handleDelete(index, row) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		//查询项目列表
		onProjectList(){
			this.queryParam.projectName = this.searchVal
			let params = Object.assign({}, this.queryParam)
			params.pageNo = this.pageParams.pageNo;
			params.pageSize = this.pageParams.pageSize;
			//已结束
			params.processStatus = "20"
			//已结束
			params.processDetailStatus = "21"
			//
			httpGet(global.gateway+this.urls.projectList,params).then(res=>{
				this.tableData = res.data.result.records;
				this.total = res.data.result.total;
			});
		},
    handleView(row){
      let url = row.resultNoticeUrl;
      window.open(url);
    },
    //跳页
    goPage(currentPage){
        this.loading = true
        this.pageParams.pageNo = currentPage
        this.onProjectList()
    },
		//展示收取服务费详情
		onShowChargeDetail(row){
			this.srcList = [];
			this.dialogFormVisible = true;
			this.form.serviceChargeDesc = row.serviceChargeDesc;
			this.form.chargeFlag = row.chargeFlag;
			this.form.chargePic = row.chargePic;
			this.srcList.push(row.chargePic);
		},
		//关闭收取服务费详情
		onCloseChargeDetail(){
			this.srcList = [];
			this.dialogFormVisible = false;
			this.form.serviceChargeDesc = '';
			this.form.chargeFlag = '1';
			this.form.chargePic = '';
		},

    /**
     * @Description: 填写开票信息
     * @Author: Niklaus
     * @Date: 2022-07-28 11:19:59
     * @param {*} id  项目ID
     * @param {*} code 项目编号
     */
    openInvoice({id, projectNo, quotationMethod}) {
      this.invoiceInfo.projectId = id
      this.invoiceInfo.projectNo = projectNo
      this.invoiceInfo.quotationMethod = quotationMethod
      this.showInvoiceDialog = true
    },

    /**
     * @Description: 提交项目的开票信息
     * @Author: Niklaus
     * @Date: 2022-07-28 15:17:59
     */
    handleSubmit(){
      this.$refs.invoiceInfoform.validate( isvalid => {
        if(isvalid) {
          let data = {...this.invoiceInfo}
          let org =  this.$ls.get("storeInfo")
          data.supplierStoreId =  org.id
          data.supplierStoreName =  org.storeName
          if(localStorage.getItem("corp_bid_customer")) {
            let user = JSON.parse(localStorage.getItem("corp_bid_customer"))
            data.createBy = user.id
          }
          httpPost(global.gateway + this.urls.addInvoice, data).then(({data}) => {
            if(data.success) {
              this.showInvoiceDialog = false
              this.$message.success('提交成功')
              this.onProjectList()
            }else {
              this.$message.error('提交失败')
            }
          })
        }
      })
    },

    /**
     * @Description: 查看 已填写的开票信息
     * @Author: Niklaus
     * @Date: 2022-07-28 15:18:38
     * @param {*} invoiceTitle
     * @param {*} invoiceTaxNumber
     */
    showInvoice({invoiceTitle, invoiceTaxNumber}) {
      this.onlyRead = true
      this.invoiceInfo.invoiceTitle = invoiceTitle
      this.invoiceInfo.invoiceTaxNumber = invoiceTaxNumber
      this.showInvoiceDialog = true
    },



    /**
     * @Description: 置空开票信息
     * @Author: Niklaus
     * @Date: 2022-07-28 11:31:01
     */
    closeInvoiceDialog(){
      this.onlyRead = false
      Object.keys(this.invoiceInfo).forEach(key => {
        this.invoiceInfo[key] = ''
      })
    }
	}
}
</script>

<style lang="scss" scoped>
.el-container {
background-color: #fff;
height: 100%;
.el-header {
display: flex;
display: -webkit-flex;
display: -ms-flexbox;
align-items: center;
h1 {
font-size: 23px;
font-weight: bold;
margin-right: 30px;
}
}
.el-main {
// position: relative;
// width: 100%;
// overflow: hidden;
.search {
display: flex;
justify-content: flex-start;
.el-input {
width: 300px;
}
}
.page-pagination {
width: 100%;
margin-top: 50px;
display: flex;
justify-content: center;
}
}
}

</style>